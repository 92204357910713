import React from "react";

const Title: React.FC = () => (
  <>
    <p>Po co mi</p>
    <h1>Typy generyczne w TypeScript</h1>
    <p>
      ??? <br />
      🤔
    </p>
  </>
);

export default Title;
